<template>
  <v-dialog v-model="dialog.show"  hide-overlay transition="dialog-bottom-transition" class="emp">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog.show = false" :loading="loading" :disabled="loading">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Open Template</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text :disabled="!valid || loading" :loading="loading" @click="open()">Open</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader>Open Task Bundle Template</v-subheader>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Templates</v-list-item-title>
            <v-list-item-subtitle>Please chose a template to open, chosen template will replace current content</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list three-line subheader>
        <v-subheader>Bundle Templates</v-subheader>
        <v-list-item>
          <v-select
            v-model="bundle"
            :error-messages="!validate(bundle, true )? ['[name] is required ']: []"
            :items="items"
            label="Bundle Template"
            color="primary"
          />
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>
<script>
import Api from "@/api/api/index.js";
export default {
  async created() {
    try {
      const bundles = (await Api.post('/employee/fetch/bundle_templates')).data.result.bundles
      this.$set(this, 'bundles', bundles)
    } catch (err) {
      console.log(err);
      this.loading = false;
      let notification = {
        msg: err.response?.data?.error?.msg || "Error, please try again later",
        color: "error"
      };
      this.$store.dispatch("user/notify", notification);
    }
  },
  methods: {
    async open() {
      if (this.valid) {
        this.loading = true
        try {
          const days = (await Api.post('employee/fetch/bundle_templates/days', {bundle:this.bundles.filter(el=>el.name == this.bundle)[0]})).data.result.days
          console.log('template days', days)
          this.$emit("set_template",days);  
          this.dialog.show =false
        } catch (err) {
          console.log(err);
          this.loading = false;
          let notification = {
            msg: err.response?.data?.error?.msg || "Error, please try again later",
            color: "error"
          };
          this.$store.dispatch("user/notify", notification);
        }
        this.loading = false
      }
    },
    validate(value, required = false, length = 5) {
      return (
        (typeof value == "string" && value?.length >= length) ||
        (!required && value?.length == 0)
      );
    }
  },
  data() {
    return {
      loading: false,
      bundle: '', 
      bundles:[],
    };
  },
  props: {
    dialog: {
      type: Object,
      default: () => ({ show: false })
    }
  },
  computed: {
    valid() {
      return !!this.bundle;
    }, 
    items(){
      return this.bundles.map(el=>el.name)
    }
  }
};
</script>

<style>
.emp{
     border-radius: 0;
    margin: 0;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
}
</style>